import React from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { toggleDarkMode } from "../../../redux/actions/themeActions";
import { RootState } from "../../../redux/store/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun } from "@fortawesome/free-regular-svg-icons";
import { faMoon } from "@fortawesome/free-solid-svg-icons";

const DarkModeToggle: React.FC = () => {
    const dispatch = useDispatch();
    const darkMode = useSelector((state: RootState) => state.theme.darkMode);

    return (
        <div style={{ 
                position: "fixed", 
                bottom: "15px", 
                display: "flex", 
                alignItems: "center", 
                gap: "10px"
            }}>
            <span style={{ fontWeight: !darkMode ? "bold" : "normal" }}>
                <FontAwesomeIcon className="pt-1" style={{fontSize: "1.3rem" }} title="Modo claro" icon={faSun} />
            </span>
            <motion.div
                onClick={() => dispatch(toggleDarkMode())}
                className="toggle-switch"
                animate={{ backgroundColor: darkMode ? "#f0f0f0" : "#323232" }}
                transition={{ duration: 0.3 }}
                    style={{
                    width: "60px",
                    height: "30px",
                    borderRadius: "50px",
                    display: "flex",
                    alignItems: "center",
                    padding: "4px",
                    cursor: "pointer",
                    position: "relative",
                }}
            >
            <motion.div
                animate={{ x: darkMode ? 32 : 0 }}
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
                    style={{
                        width: "22px",
                        height: "22px",
                        backgroundColor: darkMode ? "#222" : "#fff",
                        borderRadius: "50%",
                        boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
                    }}
                />
            </motion.div>
            <span style={{ fontWeight: darkMode ? "bold" : "normal" }}>
                <FontAwesomeIcon className="pt-1" style={{fontSize: "1.3rem" }} title="Modo oscuro" icon={faMoon} />
            </span>
        </div>
    );
};

export default DarkModeToggle;
