import React, { Fragment, useState } from 'react';
import axios from 'axios';
import Alertas from './secondary/Alertas';
import Modal from './secondary/Modal';
import { useNavigate } from 'react-router-dom';

const CalculadoraForex: React.FC = () => {
    const [tipoOperacion, setTipoOperacion] = useState<string>('compra');  
    const [precioXAUUSD, setPrecioXAUUSD] = useState<number>(0);
    const [tamañoCuenta, setTamañoCuenta] = useState<number>(0);
    const [lotajeEntrada, setLotajeEntrada] = useState<number>(0);
    const [stopLoss, setStopLoss] = useState<number>(0);
    const [takeProfit, setTakeProfit] = useState<number>(0);
    const [dolaresArriesgados, setDolaresArriesgados] = useState<number | null>(null);
    const [dolaresBeneficio, setDolaresBeneficio] = useState<number | null>(null);
    const [pipsPerdidas, setPipsPerdidas] = useState<number>(0);
    const [pipsGanancias, setPipsGanancias] = useState<number>(0);
    const [tipoCambio, setTipoCambio] = useState<number>(1);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState<'success' | 'error' | 'warning' | 'info'>('error');
    const navigate = useNavigate();

    const apiKey = "ba76251fae801e6ed85976605a0a4cf3";

    // Función para obtener el tipo de cambio al calcular
    const obtenerTipoCambio = async () => {
        const cachedData = localStorage.getItem('tipoCambioData');
        if (cachedData) {
            const { tipoCambio, timestamp } = JSON.parse(cachedData);
            const ahora = new Date().getTime();
            const doceHoras = 12 * 60 * 60 * 1000; // 12 horas en milisegundos

            if (ahora - timestamp < doceHoras) {
                setTipoCambio(tipoCambio);
                return tipoCambio;
            }
        }

        try {
            const response = await axios.get(
              `http://apilayer.net/api/live?access_key=${apiKey}&currencies=EUR&source=USD&format=1`
            );
            const tipoCambioAPI = response.data.quotes.USDEUR;
            setTipoCambio(tipoCambioAPI);

            const tipoCambioData = {
                tipoCambio: tipoCambioAPI,
                timestamp: new Date().getTime()
            };
            localStorage.setItem('tipoCambioData', JSON.stringify(tipoCambioData));
            return tipoCambioAPI;
        } catch (error) {
            console.error("Error obteniendo el tipo de cambio:", error);
            return tipoCambio; 
        }
    };

    // Función para calcular los resultados
    const calcularResultados = async () => {
        await obtenerTipoCambio();
        let stopLossPips: number = 0;;
        let takeProfitPips: number = 0;;
        let errores = false;
        setDolaresArriesgados(null);
        setDolaresBeneficio(null);
        setPipsPerdidas(0);
        setPipsGanancias(0);

        // Validar que los campos obligatorios estén completos
        if (!tipoOperacion || !precioXAUUSD || !tamañoCuenta || !lotajeEntrada || !stopLoss) {
            setAlertOpen(true);
            setAlertVariant('info');
            setAlertMessage('Por favor, informe todos los campos del formulario.');
            errores = true;
        }

        // Validar stop-loss dependiendo del tipo de operación
        if (!errores) {
            if (tipoOperacion === 'compra') {
                stopLossPips = (precioXAUUSD - stopLoss) / 0.1;
                if (stopLossPips <= 0) {
                    setAlertOpen(true);
                    setAlertVariant('error');
                    setAlertMessage('El stop-loss debe estar por debajo del precio actual para una operación de compra.');
                    errores = true;
                }
            } else {
                stopLossPips = (stopLoss - precioXAUUSD) / 0.1;
                if (stopLossPips <= 0) {
                    setAlertOpen(true);
                    setAlertVariant('error');
                    setAlertMessage('El stop-loss debe estar por encima del precio actual para una operación de venta.');
                    errores = true;
                }
            }
        }

        // Validar take-profit
        if (!errores && takeProfit !== 0 && takeProfit !== null && !Number.isNaN(takeProfit)) {
            if (tipoOperacion === 'compra') {
                takeProfitPips = (takeProfit - precioXAUUSD) / 0.1;
                if (takeProfitPips <= 0) {
                    setAlertOpen(true);
                    setAlertVariant('error');
                    setAlertMessage('El take-profit debe estar por encima del precio actual para una operación de compra.');
                    errores = true;
                }
            } else {
                takeProfitPips = (precioXAUUSD - takeProfit) / 0.1;
                if (takeProfitPips <= 0) {
                    setAlertOpen(true);
                    setAlertVariant('error');
                    setAlertMessage('El take-profit debe estar por debajo del precio actual para una operación de venta.');
                    errores = true;
                }
            }
        }

        // Si no hay errores, se procede a hacer los cálculos
        if (!errores) {
            const lotajeRealXAUUSD = (lotajeEntrada * precioXAUUSD * 100) / 100000;
            const valorPip = (0.1 / precioXAUUSD) * lotajeRealXAUUSD * 100000;
            const dolaresArriesgados = stopLossPips * valorPip;
            setDolaresArriesgados(dolaresArriesgados);
            setPipsPerdidas(stopLossPips);
            if (takeProfit !== 0 && takeProfit !== null && !Number.isNaN(takeProfit)) {
                const dolaresBeneficio = takeProfitPips * valorPip;
                setDolaresBeneficio(dolaresBeneficio);
                setPipsGanancias(takeProfitPips);
            } else {
                setDolaresBeneficio(null);
                setPipsGanancias(0);
            }
        }
    };

    const resetCampos = () => {
        setPrecioXAUUSD(0);
        setLotajeEntrada(0);
        setStopLoss(0);
        setTakeProfit(0);
        setDolaresArriesgados(null);
        setDolaresBeneficio(null);
        setTamañoCuenta(0);
    };

    // Función para cerrar el modal del resultado
    const cancelModalResultado = () => {
        setDolaresArriesgados(null);
    };

    return (
        <Fragment>
            <div className="container-fluid main">
                <div className="text-center">
                    <h1 className="pb-0" style={{ cursor: "pointer" }} onClick={() => navigate("/inicio")}>Calculadora Forex</h1>
                    <p style={{fontWeight: "lighter"}}>XAU/USD</p>
                </div>

                <div className="container-fluid content pb-3">
                    <form onSubmit={(e) => { e.preventDefault(); calcularResultados(); }}>
                        <div className="input-group">
                            <label htmlFor="tipoOperacion">Tipo de operación:</label>
                            <select id="tipoOperacion" value={tipoOperacion}
                                onChange={(e) => setTipoOperacion(e.target.value)}>
                                <option value="compra">Compra</option>
                                <option value="venta">Venta</option>
                            </select>
                        </div>
                        <div className="row">
                            <div className="col-6 ps-0">
                                <div className="input-group">
                                    <label htmlFor="precioXAUUSD">Entrada (Precio):</label>
                                    <input type="number"
                                        id="precioXAUUSD"
                                        value={precioXAUUSD}
                                        onChange={(e) => setPrecioXAUUSD(parseFloat(e.target.value))}
                                        style={{marginRight: '0'}}
                                        required />
                                </div>
                            </div>
                            <div className="col-6 pe-0">
                                <div className="input-group">
                                    <label htmlFor="tamañoCuenta">Tamaño cuenta (€):</label>
                                    <input
                                        type="number"
                                        id="tamañoCuenta"
                                        value={tamañoCuenta}
                                        onChange={(e) => setTamañoCuenta(parseFloat(e.target.value))}
                                        style={{marginRight: '0'}}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-group">
                            <label htmlFor="lotajeEntrada">Lotaje:</label>
                            <input type="number"
                                id="lotajeEntrada"
                                value={lotajeEntrada}
                                onChange={(e) => setLotajeEntrada(parseFloat(e.target.value))}
                                style={{marginRight: '0'}}
                                required />
                        </div>
                        <div className="row">
                            <div className="col-6 ps-0">
                                <div className="input-group">
                                    <label htmlFor="stopLoss">Stop-Loss (Precio):</label>
                                    <input type="number"
                                        id="stopLoss"
                                        value={stopLoss}
                                        onChange={(e) => setStopLoss(parseFloat(e.target.value))}
                                        style={{marginRight: '0'}}
                                        required />
                                </div>
                            </div>
                            <div className="col-6 pe-0">
                                <div className="input-group">
                                    <label htmlFor="takeProfit">Take-Profit (Precio):</label>
                                    <input type="number"
                                        id="takeProfit"
                                        value={takeProfit}
                                        style={{marginRight: '0'}}
                                        onChange={(e) => setTakeProfit(parseFloat(e.target.value))} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 ps-0">
                                <button className="botonResetUtilidades" type="button" onClick={(e) => { e.preventDefault(); resetCampos(); }}>Reset</button>
                            </div>
                            <div className="col-6 pe-0">
                                <button className="botonPrimarioUtilidades" type="submit">Calcular</button>
                            </div>
                        </div>
                    </form>

                    {dolaresArriesgados !== null && (
                        <Modal onClose={cancelModalResultado}>
                            <div className="modal-body" style={{padding: 0}}>
                                <h2 style={{fontWeight: "bold"}}>Resultado del cálculo</h2>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th className="text-center">Monto (€)</th>
                                            <th className="text-center">% de la Cuenta</th>
                                            <th className="text-center">Pips</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dolaresBeneficio !== null && (
                                            <tr>
                                                <td style={{fontWeight: "bold"}}>Ganancias</td>
                                                <td className="text-center">{(dolaresBeneficio * tipoCambio).toFixed(2)}</td>
                                                <td className="text-center">{((dolaresBeneficio * tipoCambio) / tamañoCuenta * 100).toFixed(2)}%</td>
                                                <td className="text-center">{pipsGanancias % 1 === 0 ? pipsGanancias : pipsGanancias.toFixed(2)}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td style={{fontWeight: "bold"}}>Pérdidas</td>
                                            <td className="text-center">{(dolaresArriesgados * tipoCambio).toFixed(2)}</td>
                                            <td className="text-center">{((dolaresArriesgados * tipoCambio) / tamañoCuenta * 100).toFixed(2)}%</td>
                                            <td className="text-center">{Number.isInteger(pipsPerdidas) ? pipsPerdidas : pipsPerdidas.toFixed(2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p className="text-center" style={{fontWeight: "lighter", margin: 0}}>XAU/USD</p>
                            </div>
                        </Modal>
                    )}
                </div>
            </div>
            <Alertas message={alertMessage} variant={alertVariant} duration={3000} open={alertOpen} onClose={() => setAlertOpen(false)} />
        </Fragment>
    );
};

export default CalculadoraForex;
