import { Fragment, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import Home from '../V2/components/Home';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store/store';

// Notificaciones programadas
/*import NotificacionesPush from './components/NotificacionesPush';
import { getMessaging } from 'firebase/messaging';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';*/

// import Menu from './components/Menu';

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBZwhjdM74EvgWYmB7GmHWGZW2c1WQz2uM",
  authDomain: "morrisyluna.firebaseapp.com",
  projectId: "morrisyluna",
  storageBucket: "morrisyluna.appspot.com",
  messagingSenderId: "871345265853",
  appId: "1:871345265853:web:b8608665f61dd5a5e22bd4",
  measurementId: "G-T5TL4ZV0R7"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
console.log(db);

// const messaging = getMessaging(app);

export default function AppV2() {
    const darkMode = useSelector((state: RootState) => state.theme.darkMode);

    useEffect(() => {
        if (darkMode) {
            document.body.classList.add("dark");
        } else {
            document.body.classList.remove("dark");
        }
    }, [darkMode]);
    
    return (
        <Fragment>
            {/* <Menu /> */}
            <BrowserRouter>
                <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/inicio" element={<Home />} />
                
                </Routes>
            </BrowserRouter>
        </Fragment>
    );
}