import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { toggleVersion } from "./redux/actions/versionActions";
import { RootState } from "./redux/store/store";
import { disableDarkMode } from "./redux/actions/themeActions";
import { flushSync } from "react-dom";

export default function ToggleVersion() {
    const dispatch = useDispatch();
    const version = useSelector((state: RootState) => state.VersionReducer.activeVersion);
    const darkMode = useSelector((state: RootState) => state.theme.darkMode);

    const cambiaVersion = () => {
        flushSync(() => {
            if (version === "V2" && darkMode) {
                dispatch(disableDarkMode());
            }
        });
        dispatch(toggleVersion());
    };  

    return (
        <div style={{ 
        position: "fixed", 
        bottom: "15px", 
        left: "50%", 
        transform: "translateX(-50%)", 
        display: "flex", 
        alignItems: "center", 
        gap: "10px"
        }}>
        <span style={{ fontWeight: version === "V1" ? "bold" : "normal" }}>V1</span>

        <motion.div
            onClick={cambiaVersion}
            animate={{ backgroundColor: version === "V2" ? "#323232" : "#323232" }} // Verde bonito: #7be234
            transition={{ duration: 0.3 }}
            style={{
                width: "60px",
                height: "30px",
                backgroundColor: "#ddd",
                borderRadius: "50px",
                display: "flex",
                alignItems: "center",
                padding: "4px",
                cursor: "pointer",
                position: "relative",
            }}
        >
            <motion.div
                animate={{ x: version === "V2" ? 30 : 0 }}
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
                style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                    boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
                }}
            />
        </motion.div>

        <span style={{ fontWeight: version === "V2" ? "bold" : "normal" }}>V2</span>
    </div>
  );
}
