/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import {
  collection,
  addDoc,
  getDocs,
  Firestore,
  getDoc,
  doc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";
import CartaReceta from "./secondary/CartaReceta";
import Modal from "./secondary/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faHeart as faHeartSolid,
  faPenToSquare,
  faPlus,
  faSliders,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons";
import { Spinner } from "react-bootstrap";
import Alertas from "./secondary/Alertas";
import { compare } from "bcryptjs-react";
import { useNavigate } from "react-router-dom";

interface Receta {
    id: string;
    nombre: string;
    imagenUrl: string;
    categoria: string;
    ingredientes: string[];
    apuntes?: string;
    isFavorite: boolean;
}

const categorias = [
  "Pasta",
  "Pizza",
  "Pescado",
  "Carnes",
  "Mariscos",
  "Verduras",
  "Legumbres",
  "Hamburguesas",
  "Arroz",
  "Sopas",
  "Ensaladas",
  "Postres",
  "Repostería",
  "Aperitivos",
  "Sandwiches",
  "Desayunos",
  "Smoothies",
  "Comida Internacional",
  "Otros",
];

const Recetas = ({ db }: { db: Firestore }) => {
  const [nombre, setNombre] = useState("");
  const [imagen, setImagen] = useState<File | null>(null);
  const [categoria, setCategoria] = useState("");
  const [ingredientes, setIngredientes] = useState<string[]>([]);
  const [nuevoIngrediente, setNuevoIngrediente] = useState("");
  const [apuntes, setApuntes] = useState("");
  const [recetas, setRecetas] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterType, setFilterType] = useState("Nombre receta");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredRecetas, setFilteredRecetas] = useState<any[]>(recetas);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isGridView, setIsGridView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState<
    "success" | "error" | "warning" | "info"
  >("error");
  const [isModalPasswordOpen, setIsModalPasswordOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [modalAction, setModalAction] = useState<"save" | "edit">("save");
  const [showFavorites, setShowFavorites] = useState(false);
  const storage = getStorage();
  const navigate = useNavigate();

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!nombre || !imagen || ingredientes.length === 0 || !categoria) {
      setAlertOpen(true);
      setAlertVariant("info");
      setAlertMessage("Por favor, completa todos los campos obligatorios.");
      return;
    }

    setLoading(true);
    try {
      const imageRef = ref(storage, `imagenes/${imagen.name}`);
      await uploadBytes(imageRef, imagen);
      const imageUrl = await getDownloadURL(imageRef);

      const recetaData = {
        nombre,
        imagenUrl: imageUrl,
        categoria,
        ingredientes,
        apuntes,
      };
      await addDoc(collection(db, "recetas"), recetaData);

      setNombre("");
      setImagen(null);
      setCategoria("");
      setIngredientes([]);
      setApuntes("");
      setIsModalOpen(false);

      setAlertOpen(true);
      setAlertVariant("success");
      setAlertMessage("Receta subida con éxito.");
      // Cargamos las recetas
      cargarRecetas();
    } catch (error) {
      console.error("Error al subir la receta:", error);
      setAlertOpen(true);
      setAlertVariant("error");
      setAlertMessage("Error al subir la receta.");
    } finally {
      setLoading(false);
    }
  };

  const cargarRecetas = async () => {
    setLoading(true);
    try {
      const snapshot = await getDocs(collection(db, "recetas"));
      const recetasList: Receta[] = snapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
        isFavorite: doc.data().isFavorite || false,
      } as Receta))
      .sort((a, b) => a.nombre.localeCompare(b.nombre)); // Ordena alfabéticamente por nombre
      setRecetas(recetasList);
      setFilteredRecetas(recetasList);
    } catch (error) {
      console.error("Error cargando las recetas: ", error);
      setAlertOpen(true);
      setAlertVariant("error");
      setAlertMessage("Error cargando las recetas.");
    } finally {
      setLoading(false);
    }
  };

  // Filtrado en tiempo real al cambiar el texto de búsqueda
  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchQuery = e.target.value;
    setSearchQuery(newSearchQuery);
    realizarFiltrado(newSearchQuery, categoryFilter, filterType); // Pasamos filterType actual
  };

  // Filtrado en tiempo real al cambiar la categoría
  const handleCategoryFilterChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newCategoryFilter = e.target.value;
    setCategoryFilter(newCategoryFilter);
    realizarFiltrado(searchQuery, newCategoryFilter, filterType); // Pasamos filterType actual
  };

  // Filtrado en tiempo real al cambiar el tipo de filtro (radio buttons)
  const handleFilterChange = (filter: string) => {
    setFilterType(filter);
    if (searchQuery.trim() !== "") {
      realizarFiltrado(searchQuery, categoryFilter, filter); // Pasamos el nuevo filter
    }
  };

  const toggleFavorites = () => {
    const favoriteRecipes = recetas.filter((receta) => receta.isFavorite);
  
    if (!showFavorites && favoriteRecipes.length === 0) {
      // Mostrar alerta si no hay favoritos y el usuario intenta activar el filtro
      setAlertOpen(true);
      setAlertVariant("info");
      setAlertMessage("No tienes recetas favoritas.");
      return;
    }

    setSearchQuery("");
    setCategoryFilter("");
    setFilterType("Nombre receta");
    setIsFilterVisible(false);
    setIsFiltered(false);

    // Realizar el filtro o restaurar la lista completa
    setFilteredRecetas(showFavorites ? recetas : favoriteRecipes);

    setShowFavorites((prev) => !prev);
  };
  

  const realizarFiltrado = (
    query: string,
    categoria: string,
    filterType: string
  ) => {
    const lowerCaseQuery = query.toLowerCase();
    const resultados = recetas.filter((receta) => {
      const matchesText =
        (filterType === "Nombre receta" &&
          receta.nombre.toLowerCase().includes(lowerCaseQuery)) ||
        (filterType === "Ingredientes" &&
          receta.ingredientes.some((ing: string) =>
            ing.toLowerCase().includes(lowerCaseQuery)
          )) ||
        (filterType === "Notas" &&
          receta.apuntes &&
          receta.apuntes.toLowerCase().includes(lowerCaseQuery));

      const matchesCategory = categoria ? receta.categoria === categoria : true;
      return matchesText && matchesCategory;
    });

    setFilteredRecetas(resultados);
    setIsFiltered(query.trim() !== "" || categoria !== ""); // Habilita el botón si hay algún filtro
  };

  const verificarContraseña = async () => {
    const docRef = doc(db, "config", "passwordDoc");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const storedPassword = docSnap.data().password;
      if (!password) {
        setAlertOpen(true);
        setAlertVariant("info");
        setAlertMessage("Por favor, introduce una contraseña.");
        return;
      }

      setLoading(true);
      const isMatch = await compare(password, storedPassword);
      if (isMatch) {
        sessionStorage.setItem("hasAccess", "true");
        setIsModalPasswordOpen(false);
        if (modalAction === "save") {
          setIsModalOpen(true);
        } else if (modalAction === "edit") {
          setIsEditing(!isEditing);
        }
        setPassword("");
        setAlertOpen(true);
        setAlertVariant("success");
        setAlertMessage("Contraseña correcta.");
      } else {
        setAlertOpen(true);
        setAlertVariant("error");
        setAlertMessage(
          "Contraseña incorrecta. Por favor, inténtalo de nuevo."
        );
      }
    } else {
      console.error("No se encontró el documento de contraseña.");
      setAlertOpen(true);
      setAlertVariant("error");
      setAlertMessage("No se encontró el documento de contraseña.");
    }
    setLoading(false);
  };

  const handleDeleteReceta = (id: string) => {
    setRecetas((prevRecetas) => prevRecetas.filter((receta) => receta.id !== id));
    setFilteredRecetas((prevRecetas) =>
      prevRecetas.filter((receta) => receta.id !== id)
    );
  };

  const borrarFiltro = () => {
    setSearchQuery("");
    setCategoryFilter("");
    setFilteredRecetas(recetas); // Muestra todas las recetas
    setIsFiltered(false); // Deshabilita el botón
  };

  const toggleMenuVisibility = () => {
    setIsMenuVisible((prev) => !prev);
  };

  const toggleGridView = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsGridView(true);
    setIsMenuVisible(false);
  };

  const toggleListView = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsGridView(false);
    setIsMenuVisible(false);
  };

  const checkAccess = () => {
    const hasAccess = sessionStorage.getItem("hasAccess");
    if (!hasAccess) {
      setModalAction("save");
      setIsModalPasswordOpen(true);
      return;
    }
    setIsModalOpen(true);
  };

  const handleFavoriteToggle = (id: string) => {
    const updatedRecetas = recetas.map((receta) =>
      receta.id === id ? { ...receta, isFavorite: !receta.isFavorite } : receta
    );
  
    setRecetas(updatedRecetas);
  
    if (showFavorites) {
      const remainingFavorites = updatedRecetas.filter((receta) => receta.isFavorite);
      if (remainingFavorites.length === 0) {
        setShowFavorites(false);
        setFilteredRecetas(updatedRecetas);
      } else {
        setFilteredRecetas(remainingFavorites);
      }
    } else if (isFiltered) {
        realizarFiltrado(searchQuery, categoryFilter, filterType);
    } else {
      setFilteredRecetas(updatedRecetas);
    }
  };
  

  const modoEditar = () => {
    const hasAccess = sessionStorage.getItem("hasAccess");
    if (!hasAccess) {
      setModalAction("edit");
      setIsModalPasswordOpen(true);
      return;
    }
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    cargarRecetas();
  }, []);

  return (
    <Fragment>
      <div className="container-fluid main">
        {loading && (
          <div className="spinner-overlay">
            <Spinner className="custom-spinner" animation="border" />
          </div>
        )}

        <div className="text-center">
            <h1 className="pb-0" style={{ cursor: "pointer" }} onClick={() => navigate("/inicio")}>Recetas</h1>
        </div>

        <div className="container-fluid content pb-2">
          <div
            className="row mb-1 align-items-center"
            style={{ height: "50px" }}
          >
            <div className="col text-center" onClick={toggleFavorites}>
              <FontAwesomeIcon
                className="edit-receta"
                title={showFavorites ? "Mostrar todas" : "Mostrar favoritos"}
                icon={showFavorites ? faHeartSolid : faHeartRegular}
                style={{
                  fontSize: "1.5rem",
                  verticalAlign: "middle",
                  color: "#d13a3a",
                }}
              />
            </div>

            <div
              className="col text-center"
              style={{
                borderLeft: "1px solid black",
                position: "relative", // Permite posicionar el menú relativo al icono
              }}
              onClick={toggleMenuVisibility}
            >
              <FontAwesomeIcon
                className="edit-receta"
                title="Vista"
                icon={isMenuVisible ? faEyeSlash : faEye}
                style={{ fontSize: "1.5rem", verticalAlign: "middle", color: "#6fbfdf" }}
              />

              {isMenuVisible && (
                <div
                  className="menu-dropdown"
                  onClick={(e) => e.stopPropagation()}
                  style={{
                    position: "absolute",
                    top: "150%", // Coloca el menú justo debajo del icono
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "white",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                    padding: "5px",
                    zIndex: 1000, // Asegura que esté por encima de otros elementos
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Sombra para dar sensación de menú flotante
                  }}
                >
                  {/* Triángulo/pico estilo diálogo */}
                  <div
                    style={{
                      position: "absolute",
                      top: "-10px", // Posiciona el pico sobre el menú
                      left: "50%",
                      transform: "translateX(-50%)",
                      width: "0",
                      height: "0",
                      borderLeft: "10px solid transparent",
                      borderRight: "10px solid transparent",
                      borderBottom: "10px solid white", // Color del triángulo
                      zIndex: 1001, // Por encima del menú
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      height: "50px", // Altura definida para los botones
                    }}
                  >
                    <div
                      className="col text-center"
                      style={{ width: "70px", borderRight: "1px solid black" }}
                    >
                      <svg
                        onClick={toggleGridView}
                        style={{ width: "2rem", height: "2rem" }}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.4 3H4.6C4.03995 3 3.75992 3 3.54601 3.10899C3.35785 3.20487 3.20487 3.35785 3.10899 3.54601C3 3.75992 3 4.03995 3 4.6V8.4C3 8.96005 3 9.24008 3.10899 9.45399C3.20487 9.64215 3.35785 9.79513 3.54601 9.89101C3.75992 10 4.03995 10 4.6 10H8.4C8.96005 10 9.24008 10 9.45399 9.89101C9.64215 9.79513 9.79513 9.64215 9.89101 9.45399C10 9.24008 10 8.96005 10 8.4V4.6C10 4.03995 10 3.75992 9.89101 3.54601C9.79513 3.35785 9.64215 3.20487 9.45399 3.10899C9.24008 3 8.96005 3 8.4 3Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.4 3H15.6C15.0399 3 14.7599 3 14.546 3.10899C14.3578 3.20487 14.2049 3.35785 14.109 3.54601C14 3.75992 14 4.03995 14 4.6V8.4C14 8.96005 14 9.24008 14.109 9.45399C14.2049 9.64215 14.3578 9.79513 14.546 9.89101C14.7599 10 15.0399 10 15.6 10H19.4C19.9601 10 20.2401 10 20.454 9.89101C20.6422 9.79513 20.7951 9.64215 20.891 9.45399C21 9.24008 21 8.96005 21 8.4V4.6C21 4.03995 21 3.75992 20.891 3.54601C20.7951 3.35785 20.6422 3.20487 20.454 3.10899C20.2401 3 19.9601 3 19.4 3Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.4 14H15.6C15.0399 14 14.7599 14 14.546 14.109C14.3578 14.2049 14.2049 14.3578 14.109 14.546C14 14.7599 14 15.0399 14 15.6V19.4C14 19.9601 14 20.2401 14.109 20.454C14.2049 20.6422 14.3578 20.7951 14.546 20.891C14.7599 21 15.0399 21 15.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7951 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V15.6C21 15.0399 21 14.7599 20.891 14.546C20.7951 14.3578 20.6422 14.2049 20.454 14.109C20.2401 14 19.9601 14 19.4 14Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.4 14H4.6C4.03995 14 3.75992 14 3.54601 14.109C3.35785 14.2049 3.20487 14.3578 3.10899 14.546C3 14.7599 3 15.0399 3 15.6V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H8.4C8.96005 21 9.24008 21 9.45399 20.891C9.64215 20.7951 9.79513 20.6422 9.89101 20.454C10 20.2401 10 19.9601 10 19.4V15.6C10 15.0399 10 14.7599 9.89101 14.546C9.79513 14.3578 9.64215 14.2049 9.45399 14.109C9.24008 14 8.96005 14 8.4 14Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>

                    <div className="col text-center" style={{ width: "70px" }}>
                      <svg
                        onClick={toggleListView}
                        style={{ width: "2rem", height: "2rem" }}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16 10C16.9319 10 17.3978 10 17.7654 9.84776C18.2554 9.64477 18.6448 9.25542 18.8478 8.76537C19 8.39782 19 7.93188 19 7C19 6.06812 19 5.60218 18.8478 5.23463C18.6448 4.74458 18.2554 4.35523 17.7654 4.15224C17.3978 4 16.9319 4 16 4L8 4C7.06812 4 6.60218 4 6.23463 4.15224C5.74458 4.35523 5.35523 4.74458 5.15224 5.23463C5 5.60218 5 6.06812 5 7C5 7.93188 5 8.39782 5.15224 8.76537C5.35523 9.25542 5.74458 9.64477 6.23463 9.84776C6.60218 10 7.06812 10 8 10L16 10Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M18 20C18.9319 20 19.3978 20 19.7654 19.8478C20.2554 19.6448 20.6448 19.2554 20.8478 18.7654C21 18.3978 21 17.9319 21 17C21 16.0681 21 15.6022 20.8478 15.2346C20.6448 14.7446 20.2554 14.3552 19.7654 14.1522C19.3978 14 18.9319 14 18 14H6C5.06812 14 4.60218 14 4.23463 14.1522C3.74458 14.3552 3.35523 14.7446 3.15224 15.2346C3 15.6022 3 16.0681 3 17C3 17.9319 3 18.3978 3.15224 18.7654C3.35523 19.2554 3.74458 19.6448 4.23463 19.8478C4.60218 20 5.06812 20 6 20L18 20Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
              className="col text-center"
              style={{
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
              }}
              onClick={() => {
                if (!showFavorites) {
                    setIsFilterVisible(!isFilterVisible);
                    if (!isFilterVisible) {
                        setIsMenuVisible(false);
                    }
                } else {
                    setAlertOpen(true);
                    setAlertVariant("info");
                    setAlertMessage("No se pueden filtrar las recetas favoritas.");
                }
              }}
            >
              <FontAwesomeIcon
                className="edit-receta"
                title={isFilterVisible ? "Cerrar" : "Filtrar"}
                icon={isFilterVisible ? faXmark : faSliders}
                style={{ fontSize: "1.5rem", verticalAlign: "middle", color: "#91d18b" }}
              />
            </div>
            <div className="col text-center" onClick={() => checkAccess()}>
              <FontAwesomeIcon
                className="edit-receta"
                title="Nueva receta"
                icon={faPlus}
                style={{ fontSize: "1.5rem", verticalAlign: "middle", color: "#c08add" }}
              />
            </div>
          </div>

          {/* Div de Filtros */}
          {isFilterVisible && (
            <div className="filter-container p-3 mb-3 border rounded">
              <div className="row">
                <div className="col-4" style={{ paddingRight: 0 }}>
                  <label>
                    <input
                      style={{ width: "auto", marginRight: "8px" }}
                      type="radio"
                      name="filter"
                      checked={filterType === "Nombre receta"}
                      onChange={() => handleFilterChange("Nombre receta")}
                    />
                    Receta
                  </label>
                </div>
                <div className="col-5" style={{ padding: 0 }}>
                  <label>
                    <input
                      style={{ width: "auto", marginRight: "8px" }}
                      type="radio"
                      name="filter"
                      checked={filterType === "Ingredientes"}
                      onChange={() => handleFilterChange("Ingredientes")}
                    />
                    Ingredientes
                  </label>
                </div>
                <div className="col" style={{ paddingLeft: 0 }}>
                  <label>
                    <input
                      style={{ width: "auto", marginRight: "8px" }}
                      type="radio"
                      name="filter"
                      checked={filterType === "Notas"}
                      onChange={() => handleFilterChange("Notas")}
                    />
                    Notas
                  </label>
                </div>
              </div>

              <select
                className="form-select mt-2"
                value={categoryFilter}
                onChange={handleCategoryFilterChange}
              >
                <option value="">Todas las categorías</option>
                {categorias.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>

              <input
                type="text"
                placeholder="Escribe para buscar..."
                value={searchQuery}
                onChange={handleSearchQueryChange}
                style={{ width: "100%", marginTop: "10px" }}
              />
              <div className="row" style={{ paddingInline: "14px" }}>
                <button
                  onClick={() => modoEditar()}
                  className="col-2 btn btn-ligth btn-block mt-2 edit-receta"
                  style={{ marginRight: "6px", border: "solid 1px" }}
                >
                  <FontAwesomeIcon
                    title={isEditing ? "Cerrar edición" : "Editar recetas"}
                    icon={isEditing ? faXmark : faPenToSquare}
                    style={{
                      fontSize: "1.2rem",
                      verticalAlign: "middle",
                      paddingBottom: "3px",
                    }}
                  />
                </button>
                <button
                  onClick={borrarFiltro}
                  className="col btn btn-danger btn-block mt-2"
                  disabled={!isFiltered}
                >
                  Borrar filtro
                </button>
              </div>
            </div>
          )}

          {/* Modal para Nueva Receta */}
          {isModalOpen && (
            <Modal onClose={() => setIsModalOpen(false)}>
              <div className="modal-body" style={{ padding: 0 }}>
                <h2>Nueva receta</h2>
                <form onSubmit={handleFormSubmit}>
                  <input
                    type="text"
                    placeholder="Nombre"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    required
                    style={{ width: "100%", marginBottom: "10px" }}
                  />
                  <input
                    type="file"
                    onChange={(e) =>
                      setImagen(e.target.files ? e.target.files[0] : null)
                    }
                    required
                    style={{ width: "100%", marginBottom: "10px" }}
                  />
                  <select
                    className="form-select mt-2"
                    value={categoria}
                    onChange={(e) => setCategoria(e.target.value)}
                    required
                    style={{ width: "100%", marginBottom: "10px" }}
                  >
                    <option value="">Selecciona una categoría</option>
                    {categorias.map((cat) => (
                      <option key={cat} value={cat}>
                        {cat}
                      </option>
                    ))}
                  </select>

                  <div>
                    <input
                      type="text"
                      placeholder="Agregar ingrediente"
                      value={nuevoIngrediente}
                      onChange={(e) => setNuevoIngrediente(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Evita que el formulario se envíe al presionar "Enter"
                          if (nuevoIngrediente.trim() !== "") {
                            setIngredientes([
                              ...ingredientes,
                              nuevoIngrediente,
                            ]);
                            setNuevoIngrediente("");
                          }
                        }
                      }}
                      style={{ width: "100%", marginBottom: 0 }}
                    />
                    <div
                      style={{
                        marginBottom: ingredientes.length > 0 ? "5px" : "0px",
                        paddingLeft: "2px",
                      }}
                    >
                      <small className="text-muted">
                        Presiona "Intro" para añadir un ingrediente.
                      </small>
                    </div>
                  </div>

                  <div className="row" style={{ marginBottom: "6px" }}>
                    {ingredientes.map((ingrediente, index) => (
                      <div className="col-6" key={index}>
                        <li className="ps-2">{ingrediente}</li>
                      </div>
                    ))}
                  </div>
                  <textarea
                    placeholder="Notas opcionales"
                    value={apuntes}
                    onChange={(e) => setApuntes(e.target.value)}
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      marginTop: "6px",
                    }}
                  />
                  <button className="botonPrimarioRecetas" type="submit">
                    Subir receta
                  </button>
                </form>
              </div>
            </Modal>
          )}

          <div
            className="recipe-list"
            style={{
              display: "grid",
              gridTemplateColumns: isGridView ? "1fr 1fr" : "1fr",
              gap: "1rem",
            }}
          >
            {filteredRecetas.map((receta, index) => (
              <CartaReceta
                key={index}
                db={db}
                id={receta.id}
                imagenUrl={receta.imagenUrl}
                nombre={receta.nombre}
                ingredientes={receta.ingredientes}
                categoria={receta.categoria}
                apuntes={receta.apuntes}
                isGridView={isGridView}
                isEditing={isEditing}
                isFavorite={receta.isFavorite}
                onDeleteReceta={handleDeleteReceta}
                onFavoriteToggle={() => handleFavoriteToggle(receta.id)}
              />
            ))}
          </div>
        </div>
      </div>
      {isModalPasswordOpen && (
        <Modal onClose={() => setIsModalPasswordOpen(false)}>
          <div className="modal-body">
            <h2>Introduce la contraseña</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                verificarContraseña();
              }}
            >
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button className="botonPrimarioRecetas" type="submit">
                Entrar
              </button>
            </form>
          </div>
        </Modal>
      )}
      <Alertas
        message={alertMessage}
        variant={alertVariant}
        duration={3000}
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
      />
    </Fragment>
  );
};

export default Recetas;
