import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import "../css/SeccionesHome.css";
import { faMapMarkedAlt, faLightbulb, faImages, faUtensils } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const secciones = [
  {
    ruta: "/viajes",
    titulo: "Viajes",
    descripcion: "Nuestros destinos favoritos",
    icono: faMapMarkedAlt,
    textoExtra: "Las aventuras y los lugares que soñamos visitar juntos",
  },
  {
    ruta: "/ideas",
    titulo: "Ideas",
    descripcion: "Planes, restaurantes y actividades",
    icono: faLightbulb,
    textoExtra: "Ideas de restaurantes y actividades que nos inspiran",
  },
  {
    ruta: "/galeria",
    titulo: "Galería",
    descripcion: "Nuestros recuerdos favoritos",
    icono: faImages,
    textoExtra: "Momentos que valen más que mil palabras",
  },
  {
    ruta: "/recetas",
    titulo: "Recetas",
    descripcion: "Sabores que compartimos",
    icono: faUtensils,
    textoExtra: "Delicias que cocinamos y disfrutamos en pareja.",
  },
];

const SeccionesHome: React.FC = () => {
  return (
    <div className="secciones-container">
      {secciones.map((sec, index) => (
        <div
          key={index}
          className={`seccion-row-mobile ${index % 2 === 0 ? "boton-izquierda" : "boton-derecha"}`}
        >
          <motion.div
            className="texto-seccion"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          >
            <p>{sec.textoExtra}</p>
          </motion.div>

          <motion.div
            className="seccion-card"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ type: "spring", stiffness: 300, damping: 20 }}
          >
            <Link to={sec.ruta} className="seccion-link">
              <FontAwesomeIcon icon={sec.icono} className="seccion-icono" />
              <h3>{sec.titulo}</h3>
              <p>{sec.descripcion}</p>
            </Link>
          </motion.div>
        </div>
      ))}
    </div>
  );
};

export default SeccionesHome;
