import { TOGGLE_VERSION } from "../actions/versionActions";

const initialState = {
    activeVersion: "V1",
};

export function VersionReducer(state = initialState, action: { type: string }) {
    switch (action.type) {
        case TOGGLE_VERSION:
            return {
                ...state,
                activeVersion: state.activeVersion === "V1" ? "V2" : "V1",
            };
        default:
            return state;
    }
};