import React, { Fragment, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/style.css';
import Alertas from './secondary/Alertas';
import Ruleta from './secondary/Ruleta.';
import { useNavigate } from 'react-router-dom';

const GanadorAleatorio: React.FC = () => {
    const [jugadores, setJugadores] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [girando, setGirando] = useState<boolean>(false);
    const [startAngle, setStartAngle] = useState(0);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState<'success' | 'error' | 'warning' | 'info'>('success');
    const [haGirado, setHaGirado] = useState(false);
    const [ganadorMostrado, setGanadorMostrado] = useState(false);
    const navigate = useNavigate();

    // Función para agregar jugadores a la lista
    const handleAddPlayer = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && inputValue.trim()) {
            e.preventDefault();

            if (jugadores.includes(inputValue.trim())) {
                setAlertOpen(true);
                setAlertVariant("info");
                setAlertMessage(`El participante "${inputValue.trim()}" ya ha sido añadido.`);
                return;
            }

            setJugadores([...jugadores, inputValue.trim()]);
            setInputValue('');
        }
    };

    // Función para resetear la lista de jugadores
    const resetJugadores = () => {
        setInputValue('');
        setJugadores([]);
        setGirando(false);
        setStartAngle(0);
    };

    // Función que comienza a "girar" la ruleta con frenado progresivo
    const iniciarRuleta = () => {
        if (jugadores.length > 0 && !alertOpen) {  // No iniciamos la ruleta si la alerta de participante duplicado está activa
            setGirando(true);
            setHaGirado(true);
            setGanadorMostrado(false);

            // Seleccionamos un ganador completamente aleatorio antes del giro
            const randomIndex = Math.floor(Math.random() * jugadores.length);
            const ganadorAleatorio = jugadores[randomIndex];

            let spinTime = 0;
            let currentSpeed = 50; // Velocidad inicial alta
            const fastSpinDuration = 3000; // 3 segundos de giro rápido
            const spinTimeTotal = Math.random() * 3 + 6 * 1000; // Duración total del giro (6-9 segundos)

            const intervalId = setInterval(() => {
                const spinAngle = currentSpeed;
                setStartAngle(prevAngle => prevAngle + (spinAngle * Math.PI / 180));

                spinTime += 30;

                if (spinTime >= fastSpinDuration) {
                    // Después de la fase rápida, comenzamos a desacelerar
                    currentSpeed *= 0.965; // Factor de desaceleración lento
                }

                // Condición para detener la ruleta
                if (currentSpeed <= 0.5 || spinTime >= spinTimeTotal) {
                    clearInterval(intervalId);
                    setGirando(false);
                    // Solo mostramos la alerta del ganador si no hay ninguna alerta activa
                    if (!alertOpen) {
                        setAlertOpen(true);
                        setAlertMessage(`¡El ganador es: ${ganadorAleatorio}!`);
                        setAlertVariant('success');
                    }
                }
            }, 30);
        }
    };

    return (
        <Fragment>
            <div className="container-fluid main">
                <div className="text-center">
                    <h1 className="pb-0" style={{ cursor: "pointer" }} onClick={() => navigate("/inicio")}>Ganador aleatorio</h1>
                </div>

                <div className="container-fluid content py-3">
                    <form>
                        <div className="form-group mb-3">
                            <label htmlFor="jugadores">Nombre del participante</label>
                            <div className="input-group" style={{ marginBottom: 0 }}>
                                <input
                                    id="jugadores"
                                    className="form-control"
                                    type="text"
                                    name="jugadores"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={handleAddPlayer}
                                    style={{ marginRight: 0 }}
                                />
                            </div>
                            <span className="info">Presiona intro para añadir un nuevo participante.</span>
                        </div>
                    </form>

                    <div className="row">
                        <div className="col-6">
                            <button className="botonResetUtilidades" type="button" onClick={(e) => { e.preventDefault(); resetJugadores(); }}>Reset</button>
                        </div>
                        <div className="col-6">
                            <button className="botonPrimario" type="button" onClick={(e) => { e.preventDefault(); iniciarRuleta(); }} disabled={girando || jugadores.length === 0}>
                                {girando ? 'Girando...' : 'Iniciar Ruleta'}
                            </button>
                        </div>
                    </div>

                    {/* Mostrar la ruleta directamente debajo del formulario */}
                    {jugadores.length > 0 && (
                        <div style={{ marginTop: '20px' }}>
                            <Ruleta
                                options={jugadores}
                                startAngle={startAngle}
                                isSpinning={girando}
                                haGirado={haGirado}
                                onSpinEnd={(ganador: string) => {
                                    if (!girando && !ganadorMostrado) {
                                        setAlertOpen(true);
                                        setAlertMessage(`¡El ganador es: ${ganador}!`);
                                        setAlertVariant('success');
                                        setGanadorMostrado(true);
                                    }
                                }}
                            />
                        </div>
                    )}

                </div>
            </div>
            <Alertas message={alertMessage} variant={alertVariant} duration={3000} open={alertOpen} onClose={() => setAlertOpen(false)} />
        </Fragment>
    );
};

export default GanadorAleatorio;
