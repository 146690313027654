import React from "react";
import ReactDOM from "react-dom/client";
import { Provider, useSelector } from "react-redux";
import store, { RootState } from "./redux/store/store";
import AppV1 from "./V1/App";
import AppV2 from "./V2/App";

const RootComponent = () => {
  const version = useSelector((state: RootState) => state.VersionReducer.activeVersion);
  return version === "V1" ? <AppV1 /> : <AppV2 />;
};

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RootComponent />
        </Provider>
    </React.StrictMode>
);
