import React, { Fragment, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/style.css';
import ToggleVersion from "../../ToggleVersion";
import Navbar from "./secondary/Navbar";
import Banner from "./secondary/Banner";
import SeccionesHome from "./SeccionesHome";

const Home = (props: any) => {

    useEffect(() => {

    }, []);

    return (
        <Fragment>
            <Navbar />
            <Banner />
            <SeccionesHome />
            <ToggleVersion />
        </Fragment>
    );
}

export default Home;