import { DISABLE_DARK_MODE, TOGGLE_DARK_MODE } from "../actions/themeActions";

const initialState = {
  darkMode: false,
};

export default function themeReducer(state = initialState, action: { type: string }) {
  switch (action.type) {
    case TOGGLE_DARK_MODE:
      return { ...state, darkMode: !state.darkMode };
    case DISABLE_DARK_MODE:
      return { ...state, darkMode: false };
    default:
      return state;
  }
}
