/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import '../css/style.css';
import LetterGrid from "./secondary/LetterGrid";
import Alertas from "./secondary/Alertas";
import Modal from "./secondary/Modal";
import reserva from "../img/reserva.png";
import { Spinner } from "react-bootstrap";
import { doc, Firestore, getDoc } from "firebase/firestore";
import { compare } from 'bcryptjs-react';
import { useNavigate } from "react-router-dom";

const words = ["ALBAICIN", "LUKA", "AMOR"];
const gridSize = window.innerWidth > 400 ? 10 : 9;

const SopaDeLetras = ({ db }: { db: Firestore }) => {
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [grid, setGrid] = useState<string[][]>([]);
    const [selectedCells, setSelectedCells] = useState<[number, number][]>([]); // Celdas seleccionadas
    const [foundWords, setFoundWords] = useState<string[]>([]); // Palabras encontradas
    const [alertOpen, setAlertOpen] = useState(false); // Control de alertas
    const [alertMessage, setAlertMessage] = useState(""); // Mensaje de alerta
    const [alertVariant, setAlertVariant] = useState<'success' | 'error' | 'warning' | 'info'>('error'); // Variantes de la alerta
    const [foundCells, setFoundCells] = useState<[number, number][]>([]); // Celdas de las palabras encontradas
    const [direction, setDirection] = useState<string | null>(null); // Dirección de selección
    const [gameOver, setGameOver] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const navigate = useNavigate();
    
    useEffect(() => {
        const checkAccess = () => {
            const hasAccess = sessionStorage.getItem('hasAccess');
            if (hasAccess) {
                setIsAuthenticated(true);
                setIsModalOpen(false);
                const emptyGrid = generateEmptyGrid(); // Generar una cuadrícula vacía
                placeWordsInGrid(emptyGrid); // Colocar palabras
                setGrid(emptyGrid); // Actualizar el estado con la nueva cuadrícula
            }
        };

        checkAccess();
    }, []);

    const verifyPassword = async () => {
        const storedPassword = await fetchPasswordFromDB();
        // const hashedInputPassword = CryptoJS.SHA256(password).toString();
        if (!password) {
            setAlertOpen(true);
            setAlertVariant('info');
            setAlertMessage('Por favor, introduce una contraseña.');
            setLoading(false);
            return;
        }
        
        setLoading(true);
        const isMatch = await compare(password, storedPassword);
        if (isMatch) {
            sessionStorage.setItem('hasAccess', 'true');
            setIsAuthenticated(true);
            setIsModalOpen(false);
            setPassword('');

            const emptyGrid = generateEmptyGrid(); // Generar una cuadrícula vacía
            placeWordsInGrid(emptyGrid); // Colocar palabras
            setGrid(emptyGrid); // Actualizar el estado con la nueva cuadrícula

            setAlertOpen(true);
            setAlertVariant('success');
            setAlertMessage('Contraseña correcta. ¡Bienvenido!');
        } else {
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Contraseña incorrecta. Por favor, inténtalo de nuevo.');
        }
        setLoading(false);
    };

    const cerrarModal = () => {
        setIsModalOpen(false);
        window.history.back();
    }

    const fetchPasswordFromDB = async () => {
        const docRef = doc(db, 'config', 'passwordDoc');
        const docSnap = await getDoc(docRef);
        return docSnap.exists() ? docSnap.data().password : '';
    };

    const generateEmptyGrid = () => {
        return Array.from({ length: gridSize }, () =>
            Array.from({ length: gridSize }, () => "")
        );
    };

    const placeWordsInGrid = (grid: string[][]) => {
        words.forEach((word) => {
            let placed = false;
            let attempts = 0;

            while (!placed && attempts < 100) {
                const direction = Math.floor(Math.random() * 3);
                const startRow = Math.floor(Math.random() * gridSize);
                const startCol = Math.floor(Math.random() * gridSize);

                if (direction === 0 && startCol + word.length <= gridSize) {
                    // Horizontal
                    if (canPlaceWordHorizontally(word, startRow, startCol, grid)) {
                        for (let i = 0; i < word.length; i++) {
                            grid[startRow][startCol + i] = word[i];
                        }
                        placed = true;
                    }
                } else if (direction === 1 && startRow + word.length <= gridSize) {
                    // Vertical
                    if (canPlaceWordVertically(word, startRow, startCol, grid)) {
                        for (let i = 0; i < word.length; i++) {
                            grid[startRow + i][startCol] = word[i];
                        }
                        placed = true;
                    }
                } else if (direction === 2 && startRow + word.length <= gridSize && startCol + word.length <= gridSize) {
                    // Diagonal
                    if (canPlaceWordDiagonally(word, startRow, startCol, grid)) {
                        for (let i = 0; i < word.length; i++) {
                            grid[startRow + i][startCol + i] = word[i];
                        }
                        placed = true;
                    }
                }
                attempts++;
            }
        });

        fillEmptySpaces(grid);
    };

    const fillEmptySpaces = (grid: string[][]) => {
        for (let row = 0; row < gridSize; row++) {
            for (let col = 0; col < gridSize; col++) {
                if (grid[row][col] === "") {
                    grid[row][col] = String.fromCharCode(65 + Math.floor(Math.random() * 26));
                }
            }
        }
    };

    const canPlaceWordHorizontally = (word: string, row: number, col: number, grid: string[][]): boolean => {
        for (let i = 0; i < word.length; i++) {
            if (grid[row][col + i] !== "") return false;
        }
        return true;
    };

    const canPlaceWordVertically = (word: string, row: number, col: number, grid: string[][]): boolean => {
        for (let i = 0; i < word.length; i++) {
            if (grid[row + i][col] !== "") return false;
        }
        return true;
    };

    const canPlaceWordDiagonally = (word: string, row: number, col: number, grid: string[][]): boolean => {
        for (let i = 0; i < word.length; i++) {
            if (grid[row + i][col + i] !== "") return false;
        }
        return true;
    };

    const isAdjacent = ([lastRow, lastCol]: [number, number], [newRow, newCol]: [number, number]) => {
        const rowDiff = Math.abs(lastRow - newRow);
        const colDiff = Math.abs(lastCol - newCol);
        return rowDiff <= 1 && colDiff <= 1;
    };

    const getDirection = ([startRow, startCol]: [number, number], [nextRow, nextCol]: [number, number]) => {
        if (startRow === nextRow) return "horizontal";
        if (startCol === nextCol) return "vertical";
        if (Math.abs(startRow - nextRow) === Math.abs(startCol - nextCol)) return "diagonal";
        return null;
    };

    const handleLetterClick = (row: number, col: number) => {
        // Si el juego ha terminado, no hacer nada
        if (gameOver) {
            return;
        }
    
        const selectedCell = [row, col] as [number, number];
        const lastCell = selectedCells[selectedCells.length - 1];
    
        if (selectedCells.length === 0) {
            setSelectedCells([selectedCell]);
        } else if (isAdjacent(lastCell, selectedCell)) {
            if (!direction) {
                const newDirection = getDirection(lastCell, selectedCell);
                if (newDirection) {
                    setDirection(newDirection);
                    setSelectedCells((prev) => [...prev, selectedCell]);
                }
            } else {
                const newDirection = getDirection(lastCell, selectedCell);
                if (newDirection === direction) {
                    setSelectedCells((prev) => [...prev, selectedCell]);
                } else {
                    resetSelectionWithFirst(selectedCell);
                }
            }
    
            const selectedWord = [...selectedCells, selectedCell].map(([r, c]) => grid[r][c]).join("");
    
            if (words.includes(selectedWord) && !foundWords.includes(selectedWord)) {
                setFoundWords((prev) => [...prev, selectedWord]);
                setFoundCells((prev) => [...prev, ...selectedCells, selectedCell]);
    
                setAlertOpen(true);
                setAlertVariant("success");
                setAlertMessage(`¡Encontraste la palabra: ${selectedWord}!`);
    
                // Verificar si ha encontrado todas las palabras
                if (foundWords.length + 1 === words.length) {
                    setAlertMessage('¡Felicidades! Has encontrado todas las palabras.');
                    setIsConfirmModalOpen(true);
                    setGameOver(true); // Terminar el juego
                }
    
                resetSelection();
            }
        } else {
            resetSelectionWithFirst(selectedCell);
        }
    };    
    
    const resetSelectionWithFirst = (firstCell: [number, number]) => {
        setSelectedCells([firstCell]); // Reiniciar pero seleccionar la nueva celda como la primera
        setDirection(null); // Restablecer la dirección
    };
    
    const resetSelection = () => {
        setSelectedCells([]);
        setDirection(null);
    };

    // Función para cancelar la eliminación
    const cancelDeletePhoto = () => {
        setIsConfirmModalOpen(false);
    };
    
    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };    

    return (
        <Fragment>
            {loading && (
                <div className="spinner-overlay">
                    <Spinner className="custom-spinner" animation="border" />
                </div>
            )}
            {isModalOpen && (
                <Modal onClose={cerrarModal}>
                    <div className="modal-body">
                        <h2>Introduce la contraseña</h2>
                        <form onSubmit={(e) => { e.preventDefault(); verifyPassword(); }}>
                            <input
                                type="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                            <button className="botonPrimarioRanking" type="submit">
                                Entrar
                            </button>
                        </form>
                    </div>
                </Modal>
            )}
            {isAuthenticated && (
                <div className="container-fluid main">
                    <div className="text-center">
                    <   h1 className="pb-0" style={{ cursor: "pointer" }} onClick={() => navigate("/inicio")}>Sopa de Letras</h1>
                    </div>
                    <LetterGrid grid={grid} onLetterClick={handleLetterClick} foundCells={foundCells} selectedCells={selectedCells} />
                    <div className="py-4">
                        <h3><strong>Instrucciones:</strong></h3>
                        <p>En esta sopa de letras encontrarás palabras relacionadas con nosotros. A continuación te doy algunas pistas para ayudarte a encontrarlas:</p>
                        
                        <ul>
                            <li><strong>Pista palabra 1</strong>: Nombre del lugar donde te pedí matrimonio (falso).</li>
                            <li><strong>Pista palabra 2</strong>: Nombre de un ratoncito travieso al cual quieres un montón.</li>
                            <li><strong>Pista palabra 3</strong>: Sentimiento intenso del ser humano que siento hacia ti.</li>
                        </ul>
                        <p>Cuando resuelvas el juego y encuentres todas las palabras, ¡te espera una pequeña sorpresa! 🎁💖</p>
                        <p>¡Suerte encontrando todas las palabras! 💖</p>
                    </div>
                </div>
            )}
            
            {isConfirmModalOpen && (
                <Modal onClose={cancelDeletePhoto}>
                    <div className="modal-body">
                        <h2>¡Este sábado nos quedamos a dormir en Valencia! 🎉</h2>
                        <p>Un pequeño regalo que te quiero hacer para disfrutar del fin de semana al máximo. 🌆✨</p>
                        <p>Prepara la maleta, que nos espera una escapada increíble.</p>
                        <p className="text-center">Te amo ❤️</p>
                        
                        <div className="content-fluid">
                            <img src={reserva} alt="Reserva de hotel en Valencia" onClick={toggleFullScreen} // Detectar clic para alternar modo pantalla completa
                                style={{
                                    width: isFullScreen ? '100vw' : '100%', // En modo pantalla completa, la imagen ocupará toda la pantalla
                                    height: isFullScreen ? '100vh' : 'auto', // Mantener proporciones en pantalla completa
                                    objectFit: isFullScreen ? 'contain' : 'cover', // Asegurar que la imagen no se deforme
                                    position: isFullScreen ? 'fixed' : 'relative', // Fijar la imagen en pantalla completa
                                    top: isFullScreen ? '0' : 'auto', // Posicionar en la parte superior en pantalla completa
                                    left: isFullScreen ? '0' : 'auto', // Alinear con el borde izquierdo
                                    zIndex: isFullScreen ? 1000 : 'auto', // Asegurar que la imagen esté sobre otros elementos en pantalla completa
                                    cursor: 'pointer', // Mostrar el cursor de clic
                                }}/>
                        </div>
                    </div>
                </Modal>
            )}
            <Alertas message={alertMessage} variant={alertVariant} duration={3000} open={alertOpen} onClose={() => setAlertOpen(false)} />
        </Fragment>
    );
};

export default SopaDeLetras;
