export const TOGGLE_DARK_MODE = "TOGGLE_DARK_MODE";
export const DISABLE_DARK_MODE = "DISABLE_DARK_MODE";

export const toggleDarkMode = () => ({
  type: TOGGLE_DARK_MODE,
});

export const disableDarkMode = () => ({
    type: DISABLE_DARK_MODE,
});
