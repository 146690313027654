import React from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { faBars, faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import "../../css/NavBar.css";

const MotionIcon = motion(FontAwesomeIcon) as React.ComponentType<
  FontAwesomeIconProps & { initial?: any; animate?: any; transition?: any }
>;

interface MenuToggleIconProps {
  isToggled: boolean;
  rotation: number;
  toggle: () => void;
}

const MenuToggleIcon: React.FC<MenuToggleIconProps> = ({ isToggled, rotation, toggle }) => {
  return (
    <div className="pt-1" onClick={toggle}>
      <MotionIcon
        className="icon-navbar"
        title="Menú"
        icon={isToggled ? faBarsStaggered : faBars}
        animate={{ rotate: rotation }}
        transition={{ duration: 0.5 }}
        style={{ transformOrigin: "center" }}
      />
    </div>
  );
};

export default React.memo(MenuToggleIcon);
