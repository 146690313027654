import React from "react";
import { motion } from "framer-motion";
import "../../css/Banner.css";
import bannerPlaya from "../../img/bannerPlaya.JPEG";
import 'bootstrap/dist/css/bootstrap.css';

const bannerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 1, ease: "easeOut" },
  },
};

const Banner: React.FC = () => {
    return (
        <motion.div className="banner" initial="hidden" animate="visible" variants={bannerVariants}>
            <img src={bannerPlaya} alt="Banner" className="banner-img" />
            <div className="banner-overlay"></div>

            <div className="banner-text-container">
                <h2 className="banner-title">MORRIS Y LUNA</h2>
                <p className="banner-slogan">Nuestro rincón, nuestras aventuras.</p>
            </div>
        </motion.div>
    );
};

export default Banner;
