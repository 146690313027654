import React, { useState, Fragment } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import NavbarItems from "./NavbarItems";
import "../../css/NavBar.css";
import DarkModeToggle from "./DarkModeToggle";
import MenuToggleIcon from "./MenuToggleIcon";

const NavBar: React.FC = () => {
  const [isToggled, setToggle] = useState(false);
  const [rotation, setRotation] = useState(0);

  const toggleMenu = () => {
    setRotation(!isToggled ? 180 : 0);
    setToggle(prev => !prev);
  };

  const navContainerVariants = {
    visible: {
        opacity: 1,
        transition: {
            x: { velocity: 100 },
            duration: 0.3,
        },
    },
    hidden: {
        opacity: 0,
        transition: {
            x: { velocity: 100 },
            duration: 0.3,
        },
    },
  };

  return (
    <header className="navbar">
        <div className="navbar-container container">
            <div className="row align-items-center w-100">
                <div className="col-2 text-center">
                    <div className="navbar-left">
                        <div className="boton-hamburguer">
                            <MenuToggleIcon isToggled={isToggled} rotation={rotation} toggle={toggleMenu} />
                        </div>
                    </div>
                </div>

                <div className="col-8 text-center">
                    <div className="navbar-center">
                        <div className="logo">MORRIS Y LUNA</div>
                    </div>
                </div>

                <div className="col-2 text-end">
                    <div className="navbar-right pe-1">
                        <Link to="/perfil">
                            <FontAwesomeIcon className="icon-navbar pt-1" title="Perfil" icon={faUser} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <AnimatePresence>
            {isToggled && (
                <Fragment>
                    <motion.div
                        className="overlay"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.5 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        onClick={() => {
                            setToggle(false);
                            setRotation(0);
                        }}                          
                    />
                    <motion.nav
                        className="drawer"
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={navContainerVariants}
                    >
                        <NavbarItems />
                        <div className="drawer-footer">
                            <DarkModeToggle />
                        </div>
                    </motion.nav>
                </Fragment>
            )}
        </AnimatePresence>
    </header>
  );
};

export default NavBar;
